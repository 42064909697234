import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// material
import { Box, Card, Link, Typography, Stack, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ColorPreview from '../../../components/ColorPreview';
import AlertOkDialog from '../../../utils/alertDialog';
import ServiceURL from '../../../utils/url';

const ProductImgStyle = styled('img')({
  height: '80px',
  width: '200px',
  objectFit: 'scale-down',
  position: 'relative'
});

// ----------------------------------------------------------------------

HighLightCard.propTypes = {
  product: PropTypes.object
};

export default function HighLightCard({ product, productUpdate, handleAdd }) {
  const { id, name, title, cover, desc } = product;
  const [fullOpen, setFullOpen] = useState(false);
  const [alertOpen, setalertOpen] = useState(false);
  const [addDialog, setDialog] = useState();
  const [productPopup, setProductPopup] = useState();
  const editHandleClose = () => {
    setDialog();
  };
  const deleteHandle = () => {
    setalertOpen(true);
  };
  const editHandle = (e) => {
    handleAdd(e, true, 'UPDATE', product);
  };
  const handleClose = () => {
    setalertOpen(!alertOpen);
  };
  const okHandle = () => {
    const requestOptions = {
      pid: id
    };
    const url = `${ServiceURL}deleteHighlight.php`;
    axios.post(url, requestOptions).then((res) => {
      setalertOpen(false);
      productUpdate();
    });
  };
  const closePreview = () => {
    setProductPopup();
  };
  return (
    <Card>
      {productPopup}
      <Stack
        direction="row"
        sx={{
          position: 'relative',
          justifyContent: 'space-between',
          width: '100%',
          zIndex: 1,
          padding: 1
        }}
      >
        <IconButton onClick={() => editHandle(product)} sx={{ padding: 0, justifyContent: 'left' }}>
          <EditIcon style={{ color: '#0077ff' }} />
        </IconButton>
        <Typography variant="h6" noWrap sx={{ cursor: 'pointer' }}>
          {title}
        </Typography>
        <IconButton onClick={() => deleteHandle()} sx={{ padding: 0, justifyContent: 'left' }}>
          <DeleteIcon style={{ color: '#cc0000' }} />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center">
        <ProductImgStyle alt={name} src={cover} />
        <Stack direction="column" alignItems="center">
          <Typography variant="caption">{desc}</Typography>
        </Stack>
      </Stack>
      <AlertOkDialog
        open={alertOpen}
        okHandle={okHandle}
        handleClose={handleClose}
        title="Delete"
        content={`Do you want to delete ${product.title}?`}
      />
    </Card>
  );
}
