import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Stack, Container, Typography, TextField, Alert,  Backdrop, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ServiceURL from '../../../utils/url';
import FileUpload from '../../../utils/fileUpload';

export default function HighlightsFullScreenDialog(details) {
  const [update, setUpdate] = useState(details.updated);
  const [BKopen, setBKopen] = useState(false);
  const validSchema = Yup.object().shape({
    heading: Yup.string()
      .matches(/^\S/, 'Whitespace is not allowed')
      .required('Heading is required'),
    descrip: Yup.string()
      .matches(/^\S/, 'Whitespace is not allowed')
      .required('Description is required')
  });
  const [imagedata, setImageData] = useState({});
  const [alertMsg, setAlert] = useState();
  const formik = useFormik({
    initialValues: {
      heading: update ? details.data.title : '',
      descrip: update ? details.data.desc : ''
    },
    validationSchema: validSchema,
    onSubmit: (values, actions) => {
      if (!imagedata.base64) {
        setAlert(
          <Alert variant="filled" severity="error">
            select image
          </Alert>
        );
        alertTimeOut();
        return null;
      }
      onAdd();
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleCallback = (imgData) => {
    setImageData(imgData);
  };

  const onAdd = () => {
    setBKopen(true);
    const requestOptions = {
      updates: update,
      id: update ? details.data.id : 0,
      name: getFieldProps('heading').value,
      desc: getFieldProps('descrip').value,
      img64: imagedata.base64
    };
    console.log(requestOptions);
    const url = `${ServiceURL}addHighlights.php`;
    axios.post(url, requestOptions).then((res) => {
      console.log(res.data);
      setBKopen(false);
      if (res.data[0].status === '0') {
        details.submit();
      } else {
        setAlert(
          <Alert variant="filled" severity="error">
            {res.data[0].msg}
          </Alert>
        );
        alertTimeOut();
      }
    }).catch(()=>{
      setBKopen(false);
      setAlert(
      <Alert variant="filled" severity="error">
            {"Network / Server Error"}
          </Alert>
      )
    });
  };
  const alertTimeOut = () => {
    setTimeout(() => {
      setAlert();
    }, 2000);
  };
  const onclose = () => {
    formik.resetForm();
    details.onClose();
  };
  return (
    <div>
      <Dialog fullScreen open={details.open} onClose={details.onClose}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BKopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onclose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Product
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              {details.button}
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          {alertMsg}
          <Stack spacing={1} justifyContent="space-between" sx={{ my: 3 }}>
            <Typography variant="h4">PRODUCT DETAILS</Typography>
            <FileUpload
              callback={handleCallback}
              update={update}
              name={details.data.title}
              cover={details.data.cover}
            />
            <TextField
              fullWidth
              type="text"
              label="Heading"
              variant="outlined"
              value={details.update ? details.data.name : ''}
              {...getFieldProps('heading')}
              error={Boolean(touched.heading && errors.heading)}
              helperText={touched.heading && errors.heading}
            />
            <TextField
              fullWidth
              type="text"
              label="Description"
              variant="outlined"
              {...getFieldProps('descrip')}
              error={Boolean(touched.descrip && errors.descrip)}
              helperText={touched.descrip && errors.descrip}
            />
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
}
