import { useState, useEffect } from 'react';
import { merge } from 'lodash';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
import ServiceURL from '../../../utils/url';
//
import { BaseOptionChart } from '../../../components/charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 378;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function AppCurrentVisits() {
  const theme = useTheme();
  const dt = new Date();
  const [CHART_DATA, setData] = useState([10, 12, 20, 24]);
  const [noVisit, setNoVisit] = useState('');
  useEffect(() => {
    spCall();
    // setInterval(spCall, 5000);
  }, []);
  const spCall = () => {
    axios.get(`${ServiceURL}getTodayVisitor.php`).then((res) => {
      // console.log(res.data);
      dataChange(res.data[0]);
      setNoVisit('');
      if (isValuesZero(res.data[0])) {
        setNoVisit('No Visits');
      }
    });
  };
  const isValuesZero = (data) => {
    let state = false;
    if (
      data.andCount === '0' &&
      data.winCount === '0' &&
      data.appleCount === '0' &&
      data.linuxCount === '0'
    ) {
      state = true;
    }
    return state;
  };
  const dataChange = (data) => {
    setData([
      parseInt(data.andCount, 10),
      parseInt(data.winCount, 10),
      parseInt(data.appleCount, 10),
      parseInt(data.linuxCount, 10)
    ]);
  };
  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.warning.main,
      theme.palette.info.main,
      theme.palette.error.main
    ],
    labels: ['Android', 'Windows', 'Apple', 'Linux'],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: true,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });

  return (
    <Card>
      <CardHeader title="Today Visits" subheader={moment(dt).format('DD-MM-YYYY')} />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={300} />
        <Typography
          variant="h5"
          style={{
            position: 'absolute',
            top: '47%',
            left: '39%',
            zIndex: 99,
            color: '#999',
            transition: 'ease-in'
          }}
        >
          {noVisit}
        </Typography>
      </ChartWrapperStyle>
    </Card>
  );
}
