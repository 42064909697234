import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, Link, Typography, Stack, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ColorPreview from '../../../components/ColorPreview';
import AlertOkDialog from '../../../utils/alertDialog';

const ProductImgStyle = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
});
const closeIconStyle = { cursor: 'pointer', float: 'right', marginTop: '5px', width: '20px' };

export default function ProductPopup(props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {props.data.name}
        <IconButton
          style={closeIconStyle}
          edge="start"
          onClick={props.previewClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{props.data.desc}</DialogContentText>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            m: 'auto',
            width: 'fit-content',
            paddingLeft: '20%',
            paddingRight: '20%'
          }}
        >
          <ProductImgStyle alt={props.data.name} src={props.data.cover} />
        </Box>
        <Stack direction="column" alignItems="center" justifyContent="space-between">
          <table width="40%">
            <tr>
              <td>
                <Typography variant="subtitle1" noWrap>
                  Id
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1" noWrap>
                  {props.data.id}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="subtitle1" noWrap>
                  Dimension
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1" noWrap>
                  {props.data.dimension}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="subtitle1" noWrap>
                  Weight
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1" noWrap>
                  {props.data.weight}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="subtitle1" noWrap>
                  Wattage
                </Typography>
              </td>
              <td>
                <Typography variant="subtitle1" noWrap>
                  {props.data.wattage}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="subtitle1" noWrap>
                  Colours
                </Typography>
              </td>
              <td>
                <ColorPreview colors={props.data.colors} />
              </td>
            </tr>
          </table>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
