import React, { useEffect, useState } from 'react';
import axios from 'axios';
// material
import { Button, Container, Stack, Typography, LinearProgress } from '@mui/material';
// components
import Page from '../components/Page';
import ServiceURL from '../utils/url';
import HighLightList from '../sections/@dashboard/highlights/HighlightList';
import HighlightsFullScreenDialog from '../sections/@dashboard/highlights/addHighLights';

// ----------------------------------------------------------------------

export default function Highlight() {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(true);
  const [addDialog, setDialog] = useState();
  const [loader, setLoader] = useState(true);
  const productsFetch = () => {
    axios.get(`${ServiceURL}getHighlights.php`).then((res) => {
      setLoader(false);
      setProducts(res.data);
    }).catch(()=>{
      setLoader(false);
    });
  };
  useEffect(() => {
    productsFetch();
  }, []);
  const Products = products.map((result) => ({
    id: result.id,
    cover: result.img,
    title: result.name,
    desc: result.descri,
    updated: result.updated
  }));
  const handleAdd = (e, upd = false, button = 'ADD', data = {}) => {
    setOpen(true);
    const add = () => {
      productsFetch();
      setDialog();
    };
    setDialog(() => (
      <HighlightsFullScreenDialog
        onClose={handleClose}
        open={open}
        submit={add}
        updated={upd}
        button={button}
        data={data}
      />
    ));
  };
  const handleClose = () => {
    setDialog();
  };
  return (
    <div>
      <Page title="Dashboard: Products">
      { loader &&
          <LinearProgress />
        }
        <Container>
          {addDialog}
          <Typography variant="h4" sx={{ mb: 5 }}>
            HighLight Products
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: 5 }}
          >
            { !loader &&
            <Button variant="contained" onClick={handleAdd}>
              ADD
            </Button>
           }
          </Stack>
          <HighLightList products={Products} productUpdate={productsFetch} handleAdd={handleAdd} />
        </Container>
      </Page>
    </div>
  );
}
