import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ColorPreview from '../../../components/ColorPreview';
import AlertOkDialog from '../../../utils/alertDialog';
import FullScreenDialog from './addProduct';
import ProductPopup from './ProductDialog';
import ServiceURL from '../../../utils/url';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product, productUpdate, handleAdd }) {
  const { id, name, modified, cover, desc, price, colors } = product;
  const [fullOpen, setFullOpen] = useState(false);
  const [alertOpen, setalertOpen] = useState(false);
  const [addDialog, setDialog] = useState();
  const [productPopup, setProductPopup] = useState();
  const editHandleClose = () => {
    setDialog();
  };
  const deleteHandle = () => {
    setalertOpen(true);
  };
  const editHandle = (e) => {
    handleAdd(e, true, 'UPDATE', product);
  };
  const handleClose = () => {
    setalertOpen(!alertOpen);
  };
  const okHandle = () => {
    const requestOptions = {
      pid: id
    };
    const url = `${ServiceURL}deleteProduct.php`;
    axios.post(url, requestOptions).then((res) => {
      setalertOpen(false);
      productUpdate();
    });
  };
  const closePreview = () => {
    setProductPopup();
  };
  const cardClickevent = () => {
    setProductPopup(<ProductPopup open data={product} previewClose={closePreview} />);
  };
  return (
    <Card>
      {productPopup}
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Stack
          direction="row"
          sx={{
            top: 8,
            position: 'absolute',
            justifyContent: 'space-between',
            width: '100%',
            zIndex: 1,
            padding: 1
          }}
        >
          <IconButton
            onClick={() => editHandle(product)}
            sx={{ padding: 0, justifyContent: 'left' }}
          >
            <EditIcon style={{ color: '#0077ff' }} />
          </IconButton>
          <IconButton onClick={() => deleteHandle()} sx={{ padding: 0, justifyContent: 'left' }}>
            <DeleteIcon style={{ color: '#cc0000' }} />
          </IconButton>
        </Stack>
        <ProductImgStyle alt={name} src={cover} />
      </Box>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="h6" noWrap sx={{ cursor: 'pointer' }} onClick={cardClickevent}>
          {name}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <ColorPreview colors={colors} />
          <Typography variant="caption" noWrap>
            modified : {modified}
          </Typography>
        </Stack>
      </Stack>
      <AlertOkDialog
        open={alertOpen}
        okHandle={okHandle}
        handleClose={handleClose}
        title="Delete"
        content={`Do you want to delete ${product.name}?`}
      />
    </Card>
  );
}
