import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Stack, Container, Typography, TextField, Checkbox, Alert, Backdrop, CircularProgress  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Box } from '@mui/system';
import FileUpload from '../../../utils/fileUpload';
import ColorPreview from '../../../components/ColorPreview';
import ServiceURL from '../../../utils/url';

export default function FullScreenDialog(details) {
  const [type, setTypeData] = useState([]);
  const [productType, setType] = useState(1);
  const [colors, setcolour] = useState();
  const [color,setclr] = useState([]);
  const [update, setUpdate] = useState(details.updated);
  const [BKopen, setBKopen] = useState(false);
  let selectedClr = [];
  const validSchema = Yup.object().shape({
    name: Yup.string().matches(/^\S/, 'Whitespace is not allowed').required('Name is required'),
    desc: Yup.string().matches(/^\S/, 'Whitespace is not allowed').required('Describe is required'),
    dimen: Yup.string()
      .matches(/^\S/, 'Whitespace is not allowed')
      .required('dimension is required'),
    Weight: Yup.string().matches(/^\S/, 'Whitespace is not allowed').required('Weight is required'),
    Watt: Yup.string().matches(/^\S/, 'Whitespace is not allowed').required('Wattage is required')
  });
  const [imagedata, setImageData] = useState({});
  const [alertMsg, setAlert] = useState();
  const formik = useFormik({
    initialValues: {
      name: update ? details.data.name : '',
      desc: update ? details.data.desc : '',
      dimen: update ? details.data.dimension : '',
      Weight: update ? details.data.weight : '',
      Watt: update ? details.data.wattage : ''
    },
    validationSchema: validSchema,
    onSubmit: (values, actions) => {
      if (!imagedata.base64) {
        setAlert(
          <Alert variant="filled" severity="error">
            select image
          </Alert>
        );
        alertTimeOut();
        return;
      }
      if (color.length === 0) {
        setAlert(
          <Alert variant="filled" severity="error">
            select colour of product
          </Alert>
        );
        alertTimeOut();
        return;
      }
      onAdd();
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    axios.get(`${ServiceURL}getType.php`).then((res) => {
      setTypeData(res.data);
    });
    axios.get(`${ServiceURL}getColor.php`).then((res) => {
      setcolour(res.data);
    });
  }, []);
  const handleCallback = (imgData) => {
    setImageData(imgData);
  };
  const onAdd = () => {
    console.log(color);
    setBKopen(true);
    const requestOptions = {
      id: update ? details.data.id : 0,
      updates: update,
      name: getFieldProps('name').value,
      desc: getFieldProps('desc').value,
      img64: imagedata.base64,
      imgname: imagedata.name,
      imgtype: imagedata.type,
      prodtype: productType,
      dimension: getFieldProps('dimen').value,
      weight: getFieldProps('Weight').value,
      wattage: getFieldProps('Watt').value,
      colour: color
    };
    console.log(requestOptions);
    const url = `${ServiceURL}addProduct.php`;
    axios.post(url, requestOptions).then((res) => {
      setBKopen(false);
      if (res.data[0].status === '0') {
        details.submit();
      } else {
        setAlert(
          <Alert variant="filled" severity="error">
            {res.data[0].msg}
          </Alert>
        );
        alertTimeOut();
      }
    }).catch(()=>{
      setBKopen(false);
      setAlert(
      <Alert variant="filled" severity="error">
            {"Network / Server Error"}
          </Alert>
      )
    });
  };
  const colourSelect = (e) => {
    if (e.target.checked) {
      //setclr([...color,e.target.value])
      color.push(e.target.value);
      console.log(color);
    } else {
      setclr(color.splice(color.indexOf(e.target.value), 1));
      console.log(color);
    }
  };
  const alertTimeOut = () => {
    setTimeout(() => {
      setAlert();
    }, 2000);
  };
  const onclose = () => {
    formik.resetForm();
    details.onClose();
  };
  return (
    <div>
      <Dialog fullScreen open={details.open} onClose={details.onClose}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BKopen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onclose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Add Product
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSubmit}>
              {details.button}
            </Button>
          </Toolbar>
        </AppBar>
        <Container maxWidth="sm">
          {alertMsg}
          <Stack spacing={1} justifyContent="space-between" sx={{ my: 3 }}>
            <Typography variant="h4">PRODUCT DETAILS</Typography>
            <FileUpload
              callback={handleCallback}
              update={update}
              name={details.data.imgName}
              cover={details.data.cover}
            />
            <TextField
              fullWidth
              type="text"
              label="Product Name"
              variant="outlined"
              value={details.update ? details.data.name : ''}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              fullWidth
              type="text"
              label="Product Description"
              variant="outlined"
              {...getFieldProps('desc')}
              error={Boolean(touched.desc && errors.desc)}
              helperText={touched.desc && errors.desc}
            />
            <TextField
              fullWidth
              type="text"
              label="Dimension"
              variant="outlined"
              {...getFieldProps('dimen')}
              error={Boolean(touched.dimen && errors.dimen)}
              helperText={touched.dimen && errors.dimen}
            />
            <TextField
              fullWidth
              type="text"
              label="Weight"
              variant="outlined"
              {...getFieldProps('Weight')}
              error={Boolean(touched.Weight && errors.Weight)}
              helperText={touched.Weight && errors.Weight}
            />
            <TextField
              fullWidth
              type="text"
              label="Wattage"
              variant="outlined"
              {...getFieldProps('Watt')}
              error={Boolean(touched.Watt && errors.Watt)}
              helperText={touched.Watt && errors.Watt}
            />
            <Box>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={update ? Number(details.data.prodType) : 1}
                name="product-type"
                id="prodType"
                onChange={(e) => setType(e.target.value)}
              >
                {type &&
                  type.map((tp) => (
                    <FormControlLabel
                      key={tp.id}
                      value={tp.id}
                      control={<Radio />}
                      label={tp.type}
                    />
                  ))}
              </RadioGroup>
            </Box>
            <Stack>
              <FormLabel>Colours</FormLabel>
              <Stack direction="row" spacing={2}>
                {colors &&
                  colors.map((cl) => {
                    let checked = false;
                    if (update && details.data.colors) {
                      details.data.colors.map((code) => {
                        if (code === cl.code) {
                          checked = true;
                        }
                        return 0;
                      });
                      if (update && checked) color.push(cl.id);
                    }
                    return (
                      <Stack direction="row">
                        <Checkbox
                          key={cl.id}
                          value={cl.id}
                          onChange={(e) => colourSelect(e)}
                          defaultChecked={update && checked}
                        />
                        <ColorPreview colors={[cl.code]} limit={1} />
                      </Stack>
                    );
                  })
                  }
                  {console.log(color)}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Dialog>
    </div>
  );
}
