import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import axios from 'axios';
// material
import { Card, CardHeader, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
//
import { BaseOptionChart } from '../../../components/charts';
import ServiceURL from '../../../utils/url';

// ----------------------------------------------------------------------

export default function AppWebsiteVisits() {
  const theme = useTheme();
  const android = [];
  const windows = [];
  const apple = [];
  const linux = [];
  const retVal = [];
  const [chart, setChart] = useState(<div height={364} />);
  const datels = (array) => {
    android.splice(0, android.length);
    windows.splice(0, windows.length);
    apple.splice(0, apple.length);
    linux.splice(0, linux.length);
    retVal.splice(0, retVal.length);
    let cdate = new Date(array[0].visitTime);
    let current = moment(cdate).format('DD MMM');
    retVal.push(current);
    for (let i = 0; i < array.length; i += 1) {
      if (current !== moment(array[i].visitTime).format('DD MMM')) {
        countValidate();
        cdate = new Date(array[i].visitTime);
        current = moment(cdate).format('DD MMM');
        retVal.push(current);
      }
      switch (array[i].platform) {
        case 'Android':
          android.push(parseInt(array[i].count, 10));
          break;
        case 'Windows':
          windows.push(parseInt(array[i].count, 10));
          break;
        case 'Apple':
          apple.push(parseInt(array[i].count, 10));
          break;
        case 'Linux':
          linux.push(parseInt(array[i].count, 10));
          break;
        default:
          console.log('Error');
          break;
      }
      if (i === array.length - 1) {
        countValidate();
      }
    }
  };
  const baseOptionchrt = BaseOptionChart();
  useEffect(() => {
    spCall();
    // setInterval(spCall, 15000);
  }, []);
  const spCall = () => {
    axios.get(`${ServiceURL}getMonthVisitor.php`).then((res) => {
      // console.log(res.data);
      datels(res.data);
      const CHART_DATA = [
        {
          name: 'Android',
          type: 'column',
          data: android
        },
        {
          name: 'Windows',
          type: 'area',
          data: windows
        },
        {
          name: 'Apple',
          type: 'column',
          data: apple
        },
        {
          name: 'Linux',
          type: 'line',
          data: linux
        }
      ];
      const chartOptions = merge(baseOptionchrt, {
        colors: [
          theme.palette.primary.main,
          theme.palette.warning.main,
          theme.palette.info.main,
          theme.palette.error.main
        ],
        stroke: { width: [0, 2, 0, 2] },
        plotOptions: { bar: { columnWidth: '40%', borderRadius: 1 } },
        fill: { type: ['solid', 'gradient', 'solid', 'solid'] },
        labels: retVal,
        xaxis: { type: 'date' },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: (y) => {
              if (typeof y !== 'undefined') {
                return `${y.toFixed(0)} visits`;
              }
              return y;
            }
          }
        }
      });
      setChart();
      setChart(
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      );
    });
  };
  const countValidate = () => {
    if (android.length === retVal.length - 1) {
      android.push(0);
    }
    if (apple.length === retVal.length - 1) {
      apple.push(0);
    }
    if (linux.length === retVal.length - 1) {
      linux.push(0);
    }
    if (windows.length === retVal.length - 1) {
      windows.push(0);
    }
  };

  return (
    <Card>
      <CardHeader title="Website Visits" subheader="Monthly visits" />
      <Box sx={{ p: 3, pb: 2 }} dir="ltr">
        {chart}
      </Box>
    </Card>
  );
}
