import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// material
import { Button, Container, Stack, Typography, Box, LinearProgress } from '@mui/material';
// components
import Page from '../components/Page';
import { ProductList } from '../sections/@dashboard/products';
import FullScreenDialog from '../sections/@dashboard/products/addProduct';
import ServiceURL from '../utils/url';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(true);
  const [addDialog, setDialog] = useState();
  const [toast, setToast] = useState(false);
  const [loader, setLoader] = useState(true);
  const productsFetch = () => {
    axios.get(`${ServiceURL}getProducts.php`).then((res) => {
      setLoader(false);
      setProducts(res.data);
    }).catch(()=>{
      setLoader(false);
    });
  };
  useEffect(() => {
    productsFetch();
  }, []);
  const Products = products.map((result) => ({
    id: result.id,
    cover: result.base64,
    imgName: result.imgName,
    name: result.name,
    modified: result.modified,
    prodType: result.type,
    desc: result.describ,
    dimension: result.dimension,
    weight: result.weight,
    wattage: result.wattage,
    colors: result.color.split(',')
  }));
  const handleAdd = (e, upd = Boolean(false), button = 'ADD', data = {}) => {
    setOpen(true);
    const add = () => {
      productsFetch();
      setDialog();
    };
    setDialog(() => (
      <FullScreenDialog
        onClose={handleClose}
        open={open}
        submit={add}
        updated={upd}
        button={button}
        data={data}
      />
    ));
  };
  const handleClose = () => {
    setDialog();
  };
  return (
    <div>
      <Page title="Dashboard: Products">
        { loader &&
          <LinearProgress />
        }
        
        <Container>
          {addDialog}
          <Typography variant="h4" sx={{ mb: 5 }}>
            Products
          </Typography>
          
          <Stack
            direction="row"
            flexWrap="wrap-reverse"
            alignItems="center"
            justifyContent="flex-end"
            sx={{ mb: 5 }}
          >{ !loader &&
            <Button variant="contained" onClick={handleAdd}>
              ADD
            </Button>
           }
          </Stack>
          <ProductList products={Products} productUpdate={productsFetch} handleAdd={handleAdd} />
        </Container>
      </Page>
    </div>
  );
}
