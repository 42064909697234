// material
import { Box, Grid, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServiceURL from '../utils/url';
// components
import Page from '../components/Page';
import AppUsers from '../sections/@dashboard/app/AppUsers';
import AppWebsiteVisits from '../sections/@dashboard/app/AppWebsiteVisits';
import AppCurrentVisits from '../sections/@dashboard/app/AppCurrentVisits';
import AppTrafficBySite from '../sections/@dashboard/app/AppTrafficBySite';
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [platform, setPlatform] = useState([]);
  let counts = [];
  useEffect(() => {
    dataChange();
    // setInterval(dataChange, 5000);
  }, []);
  const dataChange = () => {
    axios.get(`${ServiceURL}getVisitor.php`).then((res) => {
      // console.log(res.data);
      counts = [res.data.andCount, res.data.appleCount, res.data.winCount, res.data.linuxCount];
      setPlatform([
        {
          icon: 'ant-design:android-filled',
          platform: 'Android',
          count: res.data[0].andCount
        },
        {
          icon: 'ant-design:apple-filled',
          platform: 'Apple',
          count: res.data[0].appleCount
        },
        {
          icon: 'ant-design:windows-filled',
          platform: 'Windows',
          count: res.data[0].winCount
        },
        {
          icon: 'emojione-monotone:penguin',
          platform: 'Linux',
          count: res.data[0].linuxCount
        }
      ]);
    });
  };
  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome to Ectro Dashboard</Typography>
        </Box>
        <Box sx={{ pb: 3, marginLeft: 2 }}>
          <Typography variant="h5">Platform visits</Typography>
        </Box>
        <Grid container spacing={3}>
          {platform.map((value) => (
            <Grid key={value.platform} item xs={12} sm={6} md={3}>
              <AppUsers data={value} />
            </Grid>
          ))}
        </Grid>
        <Grid container marginTop={1} spacing={2} columns={{ xs: 4, md: 12 }}>
          <Grid item md={8} xs={6}>
            <AppWebsiteVisits />
          </Grid>
          <Grid item md={4} xs={6}>
            <AppCurrentVisits />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
