import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import HighLightCard from './HighlightCard';

// ----------------------------------------------------------------------

HighLightList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function HighLightList({ products, productUpdate, handleAdd, ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product) => (
        <Grid key={product.id} item xs={12} sm={12} md={12}>
          <HighLightCard product={product} productUpdate={productUpdate} handleAdd={handleAdd} />
        </Grid>
      ))}
    </Grid>
  );
}
